import {types} from "../types/types";
import {Dispatch} from "redux";
import {getLogin} from "../utils/services";

export const loginWithEmail = (email:string, password:string) =>{

    return async (dispatch:Dispatch<any>)  =>{
        try {
            const dataLogin = await getLogin(email,password);
            if(dataLogin.code === 200){
                const authData = {
                    loged:true,
                    name: email,
                    token: dataLogin.token
                }
                localStorage.setItem('auth', JSON.stringify(authData))
                dispatch(
                    login(authData.loged, authData.name, authData.token)
                )

            }else{
                return dataLogin;
            }
        }catch (e){
            return e
        }



    }
}

export const login = (loged:boolean, displayName:string,token:string) =>({
    type: types.login,
    payload: {
        loged,
        displayName,
        token
    }
})

export const logout = () =>({
    type: types.logout,
})


export const updateToken = (authData:any) =>{

    return async (dispatch:Dispatch<any>)  =>{
        localStorage.setItem('auth', JSON.stringify(authData))
        dispatch(updateState( authData));
    }

}

export const updateState = ( data:any ) =>({
    type: types.update,
    payload: data
})

