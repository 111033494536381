import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import TableCustomDetail from "../components/TableCustomDetail/TableCustomDetail";
import { getOrder } from "../utils/services";
// @ts-ignore
import ReactExport from "react-export-excel";
import { logout, login } from "../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import "./OrderDetail.css";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

interface RouteParams {
  id: string;
}

const sortData = (dataCustom: any) => {
  let { data } = dataCustom;

  let dataArray: any = [];
  let est: string[] = [];
  let bn: string[] = [];
  let oth: string[] = [];

  data[0].forEach((item: any) => {
    dataArray.push(Object.values(item));
    const providerProduct: any = [
      item.product_reference,
      item.product_name,
      item.product_quantity,
    ];
    if (item.product_reference.includes("EST")) {
      est.push(providerProduct);
    } else if (item.product_reference.includes("BA")) {
      bn.push(providerProduct);
    } else {
      oth.push(providerProduct);
    }
  });

  return {
    dataArray,
    est,
    bn,
    oth,
  };
};

const titlesOrder = [
  "Pedido",
  "Nª Pedido",
  "Fecha",
  "Tipo de pago",
  "Nombre Cliente",
  "Direccion de entrega",
  "Compras de este cliente",
  "Telefono",
  "Nota",
];
const titlesProduct = ["Referencia", "Producto", "Cantidad", "Total"];

const OrderDetail = () => {
  const { id } = useParams<RouteParams>();

  let emptyData: any = [
    {
      columns: [],
      data: [],
    },
  ];

  const history = useHistory();
  const [multiDataSet, setMultiDataSet] = useState<string[]>(emptyData);
  const [estDataSet, setEstDataSet] = useState<string[]>(emptyData);
  const [bnDataSet, setBnDataSet] = useState<string[]>(emptyData);
  const [othDataSet, setOthDataSet] = useState<string[]>(emptyData);
  const dispatch = useDispatch();
  const { loged, displayName } = useSelector((state: RootState) => state.auth);
  const [order, setOrder] = useState([]);
  const [products, setProducts] = useState([]);

  const updateData = (cab: any, data: any) => {
    let multiData = [...cab];
    const orderCustom = {
      ySteps: 2,
      columns: ["Referencia", "Producto", "Cantidad", "Precio"],
      data: data,
    };
    multiData.push(orderCustom);
    return multiData;
  };

  const calculatePrice = (products: any) => {
    products.forEach(function (item: any) {
      let totalPrice = item["product_price"] * item["product_quantity"];
      item["product_price"] = Math.round(totalPrice * 100) / 100 + " €";
    });

    setProducts(products);
  };

  const goTo = () => {
    let path = `../orders`;
    history.push(path);
  };

  const getOrderCustomDetail = async (id: number) => {
    const data = await getOrder(id);

    if (data.code === 200) {
      setOrder(data.data.order);

      calculatePrice(data.data.products);

      fillExcel(data.data.order, data.data.products);

      let token: string = data.token;
      const dataLogin = {
        loged: loged,
        name: displayName,
        token: token,
      };
      localStorage.setItem("auth", JSON.stringify(dataLogin));
      //dispatch(login(dataLogin.loged,dataLogin.displayName,dataLogin.token));
    } else {
      const data = {
        loged: false,
        name: "",
        token: "",
      };
      localStorage.setItem("auth", JSON.stringify(data));
      dispatch(logout());
    }
  };

  useEffect(() => {
    getOrderCustomDetail(parseInt(id));
  }, []);

  const fillExcel = (order: any, products: any) => {
    const orderExcel = order[0];

    //data from api
    const cab: any = [
      {
        columns: [],
        data: [
          [
            {
              value: "Pedido: ",
              style: {
                font: { bold: true },
                alignment: {
                  wrapText: true,
                },
              },
            },
            {
              value: orderExcel["reference"],
            },
          ],
          [
            {
              value: "NºPedido: ",
              width: { wch: 200 },
              style: {
                font: { bold: true },
                alignment: {
                  wrapText: false,
                },
              },
            },
            {
              value: orderExcel["id_order"],
            },
          ],
        ],
      },
      {
        ySteps: 1,
        columns: [],
        data: [
          [
            {
              value: "Fecha: ",
              style: { font: { bold: true } },
            },
            {
              value: orderExcel["date_add"],
            },
          ],
          [
            {
              value: "Pago: ",
              style: { font: { bold: true } },
            },
            {
              value: orderExcel["payment"],
            },
          ],
        ],
      },
      {
        ySteps: 1,
        columns: [],
        data: [
          [
            {
              value: "Nombre cliente: ",
              style: { font: { bold: true } },
            },
            {
              value: orderExcel["firstname"] + " " + orderExcel["lastname"],
            },
          ],
          [
            {
              value: "Compras: ",
              style: { font: { bold: true } },
            },
            {
              value: orderExcel["purchases"],
            },
          ],
          [
            {
              value: "Dirección de entrega: ",
              style: { font: { bold: true } },
            },
            {
              value: orderExcel["address1"],
            },
          ],
        ],
      },
      {
        ySteps: 1,
        columns: [],
        data: [
          [
            {
              value: "Teléfono",
              style: { font: { bold: true } },
            },
            {
              value: orderExcel["phone"],
            },
          ],
          [
            {
              value: "Nota",
              style: { font: { bold: true } },
            },
            {
              value: orderExcel["notes"] === null ? "" : orderExcel["notes"],
            },
          ],
        ],
      },
    ];
    const estDet: any = [
      {
        columns: [],
        data: [
          [
            {
              value: "Pedido: ",
              style: {
                font: { bold: true },
                alignment: {
                  wrapText: true,
                },
              },
            },
            {
              value: orderExcel["reference"],
            },
          ],
          [
            {
              value: "NºPedido: ",
              width: { wch: 200 },
              style: {
                font: { bold: true },
                alignment: {
                  wrapText: false,
                },
              },
            },
            {
              value: orderExcel["id_order"],
            },
          ],
        ],
      },
      {
        ySteps: 1,
        columns: [],
        data: [
          [
            {
              value: "Proveedor: ",
              style: { font: { bold: true } },
            },
            {
              value: "Sa Teulera",
            },
          ],
        ],
      },
    ];
    const bnDet: any = [
      {
        columns: [],
        data: [
          [
            {
              value: "Pedido: ",
              style: {
                font: { bold: true },
                alignment: {
                  wrapText: true,
                },
              },
            },
            {
              value: orderExcel["reference"],
            },
          ],
          [
            {
              value: "NºPedido: ",
              width: { wch: 200 },
              style: {
                font: { bold: true },
                alignment: {
                  wrapText: false,
                },
              },
            },
            {
              value: orderExcel["id_order"],
            },
          ],
        ],
      },
      {
        ySteps: 1,
        columns: [],
        data: [
          [
            {
              value: "Proveedor: ",
              style: { font: { bold: true } },
            },
            {
              value: "Bon Any",
            },
          ],
        ],
      },
    ];
    const othDet: any = [
      {
        columns: [],
        data: [
          [
            {
              value: "Pedido: ",
              style: {
                font: { bold: true },
                alignment: {
                  wrapText: true,
                },
              },
            },
            {
              value: orderExcel["reference"],
            },
          ],
          [
            {
              value: "NºPedido: ",
              width: { wch: 200 },
              style: {
                font: { bold: true },
                alignment: {
                  wrapText: false,
                },
              },
            },
            {
              value: orderExcel["id_order"],
            },
          ],
        ],
      },
      {
        ySteps: 1,
        columns: [],
        data: [
          [
            {
              value: "Proveedor: ",
              style: { font: { bold: true } },
            },
            {
              value: "Otro",
            },
          ],
        ],
      },
    ];
    const det: any = {
      data: [products],
    };

    const { dataArray, est, bn, oth } = sortData(det);

    if (dataArray.length > 0) {
      setMultiDataSet(updateData(cab, dataArray));
    }
    if (est.length > 0) {
      setEstDataSet(updateData(estDet, est));
    }
    if (bn.length > 0) {
      setBnDataSet(updateData(bnDet, bn));
    }
    if (oth.length > 0) {
      setOthDataSet(updateData(othDet, oth));
    }
  };

  return (
    <>
      <div>
        <button className="btn btn-primary" onClick={goTo}>
          Atras
        </button>
      </div>
      <div className="div-order-detail">
        <h1>Detalle del Pedido</h1>
        <h3>Pedido</h3>
        <TableCustomDetail
          titlesOrder={titlesOrder}
          titlesProduct={titlesProduct}
          data={order}
          products={products}
        />
        <ExcelFile
          filename={`pedido-${id}`}
          element={<button className="btn btn-primary">Descargar Excel</button>}
        >
          <ExcelSheet dataSet={multiDataSet} name="Albaran" />
          <ExcelSheet dataSet={estDataSet} name="EST" />
          <ExcelSheet dataSet={bnDataSet} name="BA" />
          <ExcelSheet dataSet={othDataSet} name="OTH" />
        </ExcelFile>
      </div>
    </>
  );
};

export default OrderDetail;
