import React, { useEffect, useRef, useState } from "react";
import "./Login.css";
import { useForm } from "../hooks/userForm";
import { useDispatch, useSelector } from "react-redux";
import { loginWithEmail } from "../actions/auth";
import { RootState } from "../store/store";

export const Login = ({ history }: any) => {
  const dispatch = useDispatch();
  const { loged } = useSelector((state: RootState) => state.auth);
  const [errorMsg, setErrorMsg] = useState('');
  const isMountedRef = useRef(true);
  const { formValues, handleInputChange } = useForm({
    email: "",
    password: "",
  });

  const { email, password } = formValues;

  const handleEmailLogin = async (e: any) => {
    e.preventDefault();

    const login: any = await dispatch(loginWithEmail(email, password));
    if(login){
      const { code, msg } = login;
      if (code !== 200) {
        setErrorMsg(msg);
      }
    }

  };

  useEffect(() => {
    isMountedRef.current = true;

    if (isMountedRef.current) {
      if (loged) {
        history.replace("/orders");
      }
    }

    return () => {
      isMountedRef.current = false;
    };
  }, [loged]);

  return (
    <>
      <div className="div-login">
        <form onSubmit={handleEmailLogin} className="form-login">
          {errorMsg!=='' && <div className="divError">{errorMsg}</div>}

          <img
            className="logo-login"
            src={process.env.PUBLIC_URL + "/frutas_mallorca_logo.jpeg"}
            alt='logo'/>
          <h3 className="auth__title">Acceso privado</h3>
          <input
            type="text"
            placeholder="Email"
            name="email"
            className="auth__input"
            autoComplete="off"
            value={email}
            onChange={(e) => handleInputChange("email", e.target.value)}
          />

          <input
            type="password"
            placeholder="Password"
            name="password"
            className="auth__input"
            value={password}
            onChange={(e) => handleInputChange("password", e.target.value)}
          />

          <button type="submit" className="btn btn-primary btn-block">
            Login
          </button>
        </form>
      </div>
    </>
  );
};
