import {
    BrowserRouter as Router,
    Switch,
    Route, Redirect
} from "react-router-dom";

import {Login} from "../views/Login";
import MainRouter from "./MainRouter";
import {useSelector} from "react-redux";
import {RootState} from "../store/store";
import React from "react";


const AppRouter = ({history}:any) => {

    const {loged} = useSelector( (state:RootState) => state.auth);

    return (
        <Router>
            <div>
                <Switch>
                    {loged ?
                        <MainRouter />
                        :
                        <>
                            <Route exact path="/" component={ Login }/>
                            <Redirect to="/" />
                        </>
                    }
                </Switch>
            </div>
        </Router>
    );
};

export default AppRouter;
