import React from 'react';
import './TableCustom.css';

interface Itable {
    "id_order": number,
    "reference": string,
    "firstname": string,
    "lastname": string,
    "total_products": number,
    "date_add": string,
}
interface ItableProps {
    titles: string[],
    data: Itable[],
    action(id:number):void,
}
const TableCustom = ({titles, data, action}:ItableProps) => {

    return (
        <div className="div-table-order">
            <table className="TableCustom-table">
                <tbody>
                    <tr>
                        {titles.map((title, idx)=>(
                            <th key={idx}>{title}</th>
                        ))}
                    </tr>
                    {data.map((item, i)=>(
                        <tr key={i} onClick={() => action(item.id_order)}>
                            <td>{item.id_order}</td>
                            <td>{item.reference}</td>
                            <td>{item.firstname +" "+item.lastname}</td>
                            <td>{Math.round(item.total_products * 100) / 100} €</td>
                            <td>{item.date_add}</td>
                        </tr>
                    ))}
                </tbody>

            </table>
        </div>
    );
};

export default TableCustom;

