import React from 'react';
import {
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import Navbar from '../components/ui/Navbar'
import Orders from "../views/Orders";
import OrderDetail from "../views/OrderDetail";

function MainRouter() {
    return (
        <>
            <Navbar />
            <div style={{paddingLeft:'1em', paddingRight:'1em'}}>
                <Switch>
                    <Route exact path="/orders" component={ Orders }/>
                    <Route exact path="/order-detail/:id" component={ OrderDetail }/>

                    <Redirect to="/orders"/>
                </Switch>
            </div>
        </>
    );
}

export default MainRouter;
