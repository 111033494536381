const url = "https://gestion.frutamallorca.es/api/controllers";

export const getOrders = async (page:number, token:string)=>{

    //let localStoageString = String(localStorage.getItem("auth"));

    //let token = String(JSON.parse(localStoageString).token);


    try {
        const res = await fetch(`${url}/orders.php?page=`+page,{
            headers: {
                'Authorization': 'Bearer ' + token,
            },
            method:'GET'
        });
        return await res.json();
    } catch (error) {
        return error;
    }
}


export const getOrder = async (id:number) => {
    let localStoageString = String(localStorage.getItem("auth"));

    let token = String(JSON.parse(localStoageString).token);


    try {
        const res = await fetch(`${url}/orders.php?id=`+id,{
            headers: {
                'Authorization': 'Bearer ' + token,
            },
            method:'GET'
        });
        return await res.json();
    } catch (error) {
        return error;
    }
}

export const getLogin = async (email:string,password:string)=> {
    try {
        const res = await fetch(`${url}/login.php`,{
            method:'POST',
            body: JSON.stringify({
                "email" : email,
                "password": password
            })
        });
        return await res.json();
    } catch (error) {
        return error;
    }
}
