import React from 'react';


interface Itable {
    "reference": string,
    "id_order": number,
    "date_add": string,
    "payment": string,
    "firstname": string,
    "lastname": string,
    "address1": string,
    "phone": string,
    "purchases": number,
    "notes": string,

}

interface Product{
    "product_name": string,
    "product_quantity": number,
    "product_price": number,
    "product_reference": string
}

interface ItableProps {
    titlesOrder: string[],
    titlesProduct: string[],
    data: Itable[],
    products: Product[]
}

const TableCustomDetail = ({titlesOrder,titlesProduct, data, products}:ItableProps) => {

    return (
        <div className="div-table-order">
            <table className="TableCustom-table">
                <tbody>
                <tr>
                    {titlesOrder.map((title, idx)=>(
                        <th key={idx}>{title}</th>
                    ))}
                </tr>
                {data.map((item, i)=>(
                    <tr key={i} >
                        <td>{item.reference}</td>
                        <td>{item.id_order}</td>
                        <td>{item.date_add}</td>
                        <td>{item.payment}</td>
                        <td>{item.firstname +" "+item.lastname}</td>
                        <td>{item.address1}</td>
                        <td>{item.purchases}</td>
                        <td>{item.phone}</td>
                        <td>{item.notes}</td>

                    </tr>
                ))}
                </tbody>

            </table>

            <table className="TableCustom-table">
                <tbody>
                <tr>
                    {titlesProduct.map((title, idx)=>(
                        <th key={idx}>{title}</th>
                    ))}
                </tr>
                {products.map((item, i)=>(
                    <tr key={i} >
                        <td>{item.product_reference}</td>
                        <td>{item.product_name}</td>
                        <td>{item.product_quantity}</td>
                        <td>{item.product_price}</td>
                    </tr>
                ))}
                </tbody>

            </table>
        </div>
    );
};

export default TableCustomDetail;
