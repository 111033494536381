import React, {useCallback, useEffect, useState} from "react";
import TableCustom from "../components/TableCustom/TableCustom";
import { getOrders } from "../utils/services";
import { useDispatch, useSelector } from "react-redux";
import {logout, login, updateToken} from "../actions/auth";
import { RootState } from "../store/store";
import "./Orders.css";

const titles = ["id", "ref", "customer", "Total", "Fecha"];
const Orders = ({ history }: any) => {

  const { loged, name, token } = useSelector((state: RootState) => state.auth);
  const [orders, setOrders] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [actualPage, setActualPage] = useState(1);
  const dispatch = useDispatch();


  const getOrdersCustom = async (page: number) => {
    const data = await getOrders(page, token);

    if (data.code === 200) {
      setOrders(data.data.orders);
      setTotalPages(data.data.total_pages);
      let newToken = data.token;

      const authData = {
        loged,
        name,
        token: newToken,
      };
      dispatch(updateToken(authData))



    } else {
      dispatch(logout());
    }
  };
  const handleIncrementPage = () => {
    if (actualPage < totalPages) setActualPage(actualPage + 1);
  };

  const handleDecrementPage = () => {
    if (actualPage > 1) setActualPage(actualPage - 1);
  };


  useEffect(() => {
    getOrdersCustom(actualPage);
  }, [actualPage]);

  const goTo = (id: number) => {
    history.push(`order-detail/${id}`);
  };

  return (
    <div className="div-orders">
      <h1>Listado de pedidos</h1>
      <TableCustom titles={titles} data={orders} action={goTo} />

      <p>{totalPages} paginas</p>
      <div style={{paddingBottom:5}}>
        <button className="btn btn-primary next" onClick={handleDecrementPage}>
          Atras
        </button>
        <button
            className="btn btn-primary previous"
            onClick={handleIncrementPage}
        >
          Siguiente
        </button>
      </div>

    </div>
  );
};

export default Orders;
