import {types} from "../types/types";

const initialState = {
    loged: false,
    name:'',
    token:''
}

const init = () => {

    return JSON.parse( localStorage.getItem('auth') ||JSON.stringify(initialState) );
}


export const authReducer = (state = init() , action:any) =>{
    switch (action.type){
        case types.login:
            return {
                ...state,
                loged: action.payload.loged,
                name: action.payload.displayName,
                token: action.payload.token
            }
        case types.logout:
            return initialState
        case types.update:
            return {
                ...state,
                loged: action.payload.loged,
                name: action.payload.displayName,
                token: action.payload.token
            }
        default:
            return state
    }
}
