import { useState } from 'react';


export const useForm = <T extends Object>( initialState: T ) => {

    const [formValues, setFormValues] = useState(initialState);

    const handleInputReset = () => {
        setFormValues( initialState );
    }

    const handleInputChange = ( field: keyof T, value: string,  ) => {

        setFormValues({
            ...formValues,
            [field]: value
        });
    }

    return {
        formValues,
        handleInputChange,
        handleInputReset
    }

}
