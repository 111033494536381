import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../../actions/auth";
import './Navbar.css';
const Navbar = () => {

    const dispatch =  useDispatch();

    const handleCloseSesion = ()=>{
        const data = {
            loged: false,
            name: '',
            token: ''
        }
        localStorage.setItem('auth',JSON.stringify(data))
        dispatch(logout())
    }
    return (
        <div className="navbar">
            <button className="btn btn-danger" onClick={handleCloseSesion} >X</button>
            <img className="logo-navbar" src={process.env.PUBLIC_URL + '/frutas_mallorca_logo.jpeg'}/>
        </div>
    );
};

export default Navbar;
